import React from 'react'
import AbsoluteImage from './AbsoluteImage'
import Figure from './Figure'

const CustomVariable = ({node}) => {
    const {variable, settings} = node

    const [type, data] = variable.split(":")

    

    if(type === "filterImage") return <AbsoluteImage node={{...settings, image: {_type: "image", asset: {_id: data, id: data}}}} />
    if(type === "technicalImage") {
        if (data && data != "undefined"){
            return <Figure node={{...settings,  asset: {_id: data, id: data}}} className={settings.className} />
        }else{
            return null;
        }
    }

    return (
        <div>missing variable</div>
    )
}

export default CustomVariable