import React, { useContext, useEffect, useState } from "react";

import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImageData } from "gatsby-source-sanity";
import clientConfig from "../../client-config";
import SeoContext from "./context/seoContext";

const Image = ({
  children,
  className = "",
  style = {},
  opacity = "100",
  height,
  width = "100%",
  bgSize,
  title = undefined,
}) => {
  const imageAssetId = children.props?.blocks?.asset?.id;
  const { extension, url } = children.props?.blocks?.asset;
  const [fluidProps, setFluidProps] = useState(
    getGatsbyImageData(imageAssetId, { maxWidth: 1800 }, clientConfig.sanity)
  );
  const { pageTitle } = useContext(SeoContext);

  useEffect(() => {
    setFluidProps(getGatsbyImageData(imageAssetId, { maxWidth: 1800 }, clientConfig.sanity));
  }, []);

  return (
    <figure className={className} style={style}>
      {extension === "svg" ? (
        <img
          src={url}
          className={`transform scale-200 sm:scale-150 md:scale-100 opacity-${opacity} h-auto w-auto ${
            bgSize === "cover" ? "min-h-full min-w-full" : ""
          } ${bgSize === "contain" ? "max-w-full max-h-full" : ""}`}
          style={{
            width: width,
            height: height,
            objectFit: bgSize === "cover" ? "cover" : "contain",
          }}
          alt={
            title ||
            children.props?.blocks?.asset?.alt ||
            (pageTitle?.startsWith("Filter") && pageTitle.split(" | ")[0]) ||
            "Missing alt text"
          }
        />
      ) : (
        <GatsbyImage
          image={fluidProps}
          className={`transform scale-200 sm:scale-150 md:scale-100 opacity-${opacity} h-auto w-auto ${
            bgSize === "cover" ? "min-h-full min-w-full" : ""
          } ${bgSize === "contain" ? "max-w-full max-h-full" : ""}`}
          style={{
            width: width,
            height: height,
          }}
          alt={
            title ||
            children.props?.blocks?.asset?.alt ||
            (pageTitle?.startsWith("Filter") && pageTitle.split(" | ")[0]) ||
            "Missing alt text"
          }
          objectFit={bgSize === "cover" ? "cover" : "contain"}
        />
      )}
    </figure>
  );
};

export default Image;
