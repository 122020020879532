import React from "react";
import PortableText from "./portableText";

const Buttons = ({ node }) => {
  // Purge: items-start items-center items-end
  // Purge: sm:justify-start sm:justify-center sm:justify-end 

  // <div className={`flex flex-col sm:flex-row items-${node.alignment || "start"} justify-center sm:items-center sm:justify-${node.alignment || "center"} my-4 md:my-6 xl:my-8`}></div>
  // <div className={`flex flex-row items-${node.alignment || "start"} justify-center items-center justify-${node.alignment || "center"} my-4 md:my-6 xl:my-8`}></div>
  return (
    <div className={`flex flex-row items-${node.alignment || "start"} justify-center items-center justify-${node.alignment || "center"} my-4 md:my-6 xl:my-8`}>
      {node.buttonGroup?.map((b, i) => (
        <div className={i > 0 ? " mt-0 ml-2 md:ml-4" : ""} key={b._key}>
          <PortableText blocks={b} key={b._key} />
        </div>
      ))}
    </div>
  );
};

export default Buttons;
