import React from "react";

const Spacer = ({ node }) => {

  if (node.space === "s") return <div className="w-full h-2 md:h-4"></div>;
  if (node.space === "l") return <div className="w-full h-10 md:h-20"></div>;

  return <div className="w-full h-5 md:h-10"></div>;
};

export default Spacer;
