import React, { useEffect, useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImageData } from "gatsby-source-sanity";
import clientConfig from "../../client-config";

const Figure = ({ node, className }) => {
  if (!node || !node.asset || !node.asset._id) {
    return null;
  }
  const [fluidProps, setFluidProps] = useState(
    getGatsbyImageData(node.asset._id, { maxWidth: 675 }, clientConfig.sanity)
  );

  useEffect(() => {
    if (Object.entries(fluidProps).length > 0) return;
    setFluidProps(getGatsbyImageData(node.asset._id, { maxWidth: 675 }, clientConfig.sanity));
  }, [node]);

  return (
    <figure className={`${className}  w-full flex flex-col items-center`}>
      {fluidProps && (
        <GatsbyImage
          image={fluidProps}
          alt={node.alt || "Missing alt"}
          className="rounded-3xl md:rounded-container w-full "
          style={{ transform: "translateZ(0)" }}
        />
      )}
      <figcaption className="text-center py-4 text-xs">{node.caption}</figcaption>
    </figure>
  );
};

export default Figure;
