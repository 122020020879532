import React, { useEffect, useRef, useState } from "react";

function loadImg(url) {
  const img = new Image();

  return new Promise((resolve, reject) => {
    img.onload = () => resolve(img);
    img.src = url;
  });
}

const ImageCanvas = ({ imageData, align }) => {
  const canvasRef = useRef(null);
  const [images, setImages] = useState([]);
  const [frameIndex, setFrameIndex] = useState(0);

  const numFrames = imageData?.length - 1;

  function preloadImages() {
    const promises = [];

    for (let i = 0; i <= numFrames; i++) {
      const imgSrc = imageData[i]?.asset?.url;
      const promise = loadImg(imgSrc);
      promises.push(promise);
    }

    Promise.all(promises).then(setImages);
  }

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const { y, height } = canvasRef?.current?.getBoundingClientRect();
    const scrollFraction = -(y - windowHeight / 1.5) / height;

    const index = Math.max(Math.min(numFrames - 1, Math.ceil(scrollFraction * numFrames)), 0);

    setFrameIndex(index);
  };

  const renderCanvas = () => {
    const context = canvasRef.current.getContext("2d");
    context.canvas.width = images[0]?.width || 500;
    context.canvas.height = images[0]?.height || 500;
  };

  useEffect(() => {
    if (images.length === 0) return preloadImages();

    renderCanvas();
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [images]);

  useEffect(() => {
    if (
      !canvasRef.current ||
      images.length < numFrames ||
      frameIndex < 0 ||
      frameIndex > numFrames
    ) {
      return;
    }

    const context = canvasRef.current.getContext("2d");
    let requestId;

    const render = () => {
      context.drawImage(images[frameIndex], 0, 0);
      requestId = requestAnimationFrame(render);
    };

    render();

    return () => cancelAnimationFrame(requestId);
  }, [frameIndex, images]);

  return (
    <canvas
      ref={canvasRef}
      className={`${
        align === "center" ? "mx-auto" : align === "left" ? "" : "float-right"
      } max-w-full`}
    />
  );
};

const ThreeDImage = ({ node }) => {
  return (
    <>
      <ImageCanvas imageData={node.images} align={node.align} />
    </>
  );
};

export default ThreeDImage;
