import React from "react";

const Testimonial = (node) => {
  return (
    <article>
      <blockquote className="font-extrabold text-lg md:text-2xl leading-relaxed w-full">
        {node.quote}
      </blockquote>
      <div className="my-10">
        <p className=" leading-none text-sm">{`${node.name}${node.position ? `, ${node.position}` : ""}`}</p>
        {node.company && <p className=" leading-none text-sm">{node.company}</p>}
      </div>
    </article>
  );
};

export default Testimonial;
