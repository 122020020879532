import AbsoluteImage from "./AbsoluteImage";
import Author from "./author";
import Buttons from "./buttons";
import Compatibles from "./compatibles";
import CustomButton from "./button";
import CustomVariable from "./CustomVariable";
import DealersBlock from "./DealersBlock";
import Figure from "./Figure";
import Filter from "./filter";
import FiltersBlock from "./filter/filtersBlock";
import InternalLink from "./InternalLink";
import React from "react";
import SecondDescription from "./secondDescription";
import Section from "./section";
import SingleDealerBlock from "./SingleDealerBlock";
import Spacer from "./spacerBlock";
import Testimonial from "./testimonial";
import TestimonialsBlock from "./testimonial/testimonialsBlock";
import TextAnimation from "./textAnimation";
import ThreeDImage from "./ThreeDImage";
import ThreeDImageDefined from "./ThreeDImageDefined";
import VideoBlock from "./videoBlock";

const serializers = {
  types: {
    authorReference: ({ node }) => <span>{node.author.name}</span>,
    mainImage: ({ node }) => <Figure node={node} alt={node.alt || "Image"} />,
    section: Section,
    buttons: ({ node }) => <Buttons node={node} />,
    button: ({ node }) => (
      <CustomButton level={node.type} link={node.link} cb={() => false}>
        {node.text}
      </CustomButton>
    ),
    image: ({ node }) => <Figure {...node} alt={node.alt || "Image"} />,
    testimonialsBlock: ({ node }) => <TestimonialsBlock {...node} />,
    compatibles: ({ node }) => <Compatibles {...node} />,
    secondDescription: ({ node }) => <SecondDescription {...node} />,
    filtersBlock: ({ node }) => <FiltersBlock {...node} />,
    filter: ({ node }) => <Filter {...node} />,
    testimonial: ({ node }) => <Testimonial {...node} />,
    absoluteImage: ({ node }) => <AbsoluteImage node={node} />,
    dealersBlock: ({ node }) => <DealersBlock node={node}></DealersBlock>,
    dealer: ({ node }) => <SingleDealerBlock node={node} />,
    author: ({ node }) => <Author node={node} />,
    spacerBlock: ({ node }) => <Spacer node={node} />,
    videoBlock: ({ node }) => <VideoBlock node={node} />,
    customVariable: ({ node }) => <CustomVariable node={node} />,
    threeDImage: ({ node }) => <ThreeDImage node={node} />,
    threeDImageDefined: ({ node }) => <ThreeDImageDefined node={node} />,
  },
  marks: {
    uppercase: ({ children }) => <span className="uppercase">{children}</span>,
    center: ({ children }) => <span className="text-center mx-auto block ">{children}</span>,
    left: ({ children }) => <span className="text-left block ">{children}</span>,
    animation: ({ children, mark }) => (
      <TextAnimation type={mark.type} className="block">
        {children}
      </TextAnimation>
    ),
    internalLink: ({ children, mark }) => (
      <InternalLink link={mark.internalReference.slug?.current} type={mark.internalReference._type}>
        {children}
      </InternalLink>
    ),
  },
};

export default serializers;
