import React, { useState, useEffect } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImageData } from "gatsby-source-sanity";
import clientConfig from "../../client-config";

const Author = ({ node }) => {
  if (!node?.image?.asset?._id) {
    return null;
  }
  const [fluidProps, setFluidProps] = useState(
    getGatsbyImageData(node.image.asset._id, { maxWidth: 675 }, clientConfig.sanity)
  );

  useEffect(() => {
    setFluidProps(getGatsbyImageData(node.image.asset._id, { maxWidth: 675 }, clientConfig.sanity));
  }, []);

  const { designPosition, hideImage } = node;

  if (designPosition === "center-next")
    return (
      <div className="w-5/6 md:w-1/2 lg:w-2/5 xl:w-1/3 flex mx-auto items-center py-12">
        {!hideImage && (
          <div className="rounded-full w-24 h-24 flex-shrink-0 flex-grow-0 ring-4 ring-inset ring-navy ring-opacity-60 overflow-hidden shadow-xl">
            <GatsbyImage
              image={fluidProps}
              alt={node.image.alt || "Missing image alt text"}
              className="z-hidden overflow-hidden rounded-full h-24 w-24"
            />
          </div>
        )}
        <span className="text-sm md:text-base ml-4 md:ml-6 ">
          {node.name},{" "}
          {node.position && (
            <>
              <br />
              {node.position}
            </>
          )}{" "}
          {node.company && (
            <>
              <br />
              {node.company}
            </>
          )}
        </span>
      </div>
    );

  return (
    <div className="md:w-1/2 flex flex-col py-12 justify-center">
      {!hideImage && (
        <div className="rounded-full w-24 h-24 flex-shrink-0 flex-grow-0 ring-4 ring-inset ring-navy ring-opacity-60 overflow-hidden shadow-xl">
          <GatsbyImage
            image={fluidProps}
            alt={node.image.alt || "Missing image alt text"}
            className="z-hidden overflow-hidden rounded-full h-24 w-24"
          />
        </div>
      )}
      <span className="text-sm md:text-base mt-4 ">
        {node.name},{" "}
        {node.position && (
          <>
            <br />
            {node.position}
          </>
        )}{" "}
        {node.company && (
          <>
            <br />
            {node.company}
          </>
        )}
      </span>
    </div>
  );
};

export default Author;
