import React, { useContext } from "react";
import { ScrollContext } from "./section";
const TextAnimation = ({ children, type }) => {
  const scrollProgress = useContext(ScrollContext);

  switch (type) {
    case "scroll__extend":
      return (
        <span
          className="transition-all transform-gpu flex justify-center overflow-visible whitespace-nowrap w-full px-0"
          style={{ letterSpacing: scrollProgress * 3 + "vw" }}
        >
          {children}
        </span>
      );
  }
  return <span>{children}</span>;
};
export default TextAnimation;
