import React from "react";

const contactSerializer = (type, data) => {
  switch(type) {
    case "person":
      return {url: false, text: `Contact:`}
    case "mob":
      return {url: `tel:${data}`, text: `Mob:`}
    case "tel":
      return {url: `tel:${data}`, text: `Tel:`}
    case "fax":
      return {url: `tel:${data}`, text: `Fax:`}
    case "email":
      return {url: `mailto:${data}`, text: `Email:`}
    case "web":
      return {url: `//${data}`, text: `Web:`}
    default:
      return {url: data, text: ""}
  }
}

const SingleDealerBlock = ({ node }) => {
  return (
    <div className="w-full md:h-full rounded-3xl lg:rounded-container border-2 md:border-4 border-navy p-4 md:p-8 md:pt-4 xl:p-10 xl:pt-5 m-2 md:m-0 shadow-md">
      <h4 className="uppercase font-extrabold mb-2 md:mb-4">{node.region}</h4>
      <h4 className="text-base">{node.name}</h4>
      <p className="text-sm">Address: {node.address}</p>
      {node.contact.map((contact, b) => {
        const typeData = contactSerializer(contact.contactType[0], contact.data)

        if(!typeData.url) return <p className="mx-0 w-full my-0 text-sm font-bold py-px" key={b}>{typeData.text} {contact.data}</p>
        return <h2 className="mx-0 w-full my-0 text-dark_blue text-sm py-px" key={b}>{typeData.text} <a className="text-base text-blue" href={typeData.url}>{contact.data}</a></h2>
      })}
    </div>
  );
};

export default SingleDealerBlock;
