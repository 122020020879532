import React from 'react'

const TexturedBackground = ({children, no_margin_bottom}) => {

    var mb24 = "-mb-24";
    if (no_margin_bottom){
        mb24 = "";
    }
    return (
        <div className={"testimonial relative shadow-2xl overflow-hidden rounded-3xl md:rounded-container -mx-4 "+mb24} style={{transform: "translateZ(0)"}}>
            <div className="bg-gradient-to-b lg:bg-gradient-to-r to-dove_blue via-navy from-dark_navy absolute inset-0 opacity-25"></div>
            <div className="bg-card-pattern absolute inset-0 bg-repeat bg-bottom lg:bg-right-2 bg-small opacity-50"></div>
            <div className="shadow-2xl h-11/12 w-full left-0 lg:-translate-x-1/5 bg-dusty_blue -translate-y-40 lg:translate-y-0 lg:h-full rounded-3xl md:rounded-container transform -skew-y-12 lg:skew-y-0 lg:-skew-x-24">
                <div className="transform skew-y-12 lg:skew-y-0 lg:skew-x-24 lg:w-5/6 lg:translate-x-1/4 translate-y-40 lg:translate-y-0">
                    {children}
                </div>
            </div>
        </div>  
    )
}

export default TexturedBackground