import React, { useContext } from "react";
import Image from "./Image";
import PortableText from "./portableText";
import { ScrollContext } from "./section";

const AbsoluteImage = ({ node }) => {
  const scale = useContext(ScrollContext)

  return (
    <div
      style={{ 
        zIndex: `${node.zIndex === true ? "-1" : ""}`, 
        transform: ` translateX(-50%) translateY(-50%)`, 
        height: node.backgroundSize === "custom" && node.customSizes?.height,
        width: node.backgroundSize === "custom" && node.customSizes?.height ? "100%" : node.customSizes?.width,
        left: `${node.horizontal}%`,
        top: `${node.vertical}%`,
      }}
      className="absolute inset-0  pointer-events-none w-full h-full no-anim transform -translate-x-1/2 -translate-y-1/2"
    >
      <Image
        className={`pointer-events-none relative transition-transform `}
        height={"100%"}
        width={"100%"}
        opacity={node.backgroundOpacity}
        style={{
          height: "100%",
          width: "100%",
          transform: `${node.backgroundAnimation === "scroll__scale" ? `scale(${scale < 0.4 ? 0.4 : scale > 0.8 ? 0.8 : scale})`: ""}`
        }}
      >
        <PortableText blocks={node.image} />
      </Image>
    </div>
  );
};

export default AbsoluteImage;
