import React from "react";

const ContainerFullWidth = ({
  children,
  color = "white",
  text = "black",
  sectionClass = "",
  containerClass = "",
}) => {
  return (
    <section className={`section-gsap bg-${color} text-${text} w-full ${sectionClass}`} style={{transform: "translateZ(0)"}}>
      <div className={`${containerClass}`}>{children}</div>
    </section>
  );
};

export default ContainerFullWidth;
