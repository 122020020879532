import React, { useContext } from "react";
import CustomButton from "./button";
import TexturedBackground from "./TexturedBackground";

const SecondDescription = (node) => {
  

  if (node.dataText){
    return (
      <TexturedBackground no_margin_bottom={true}>
        <div className="w-full p-6 lg:pl-12  relative z-1  lg:top-0  rounded-3xl md:rounded-container ">
          {/* <h4 className=" pt-24 lg:pt-0  ">{node.secondDescription}</h4> */}
          
          <div className="pb-7 pt-4 column" style={{}}>

            <div dangerouslySetInnerHTML={{__html: node.dataText}}></div>
            
          </div>
        </div>
        <span className="absolute z-1  bottom-8 left-1/2 transform -translate-x-1/2 lg:bottom-24 lg:translate-x-1/2 lg:right-16 xl:right-0">
          {/* <CustomButton level="secondary" cb={() => console.log("Hello")}>
            {"See cases"}
          </CustomButton> */}
        </span>
      </TexturedBackground>
    );
  }
  return (null);
};

export default SecondDescription;
