import React from "react";
import PortableText from "./portableText";

const DealersBlock = ({ node }) => {
  return (
    <div>
      <h4 className="text-center uppercase">{node.title}</h4>
      <div className="w-full xl:w-8/12 flex flex-col mt-8 md:mt-16 items-center  lg:w-10/12 md:gap-4  md:mx-auto md:grid  md:grid-cols-2">
        {node.dealers.map((dealer, a) => (
          <PortableText blocks={dealer} key={a} />
        ))}
      </div>
    </div>
  );
};

export default DealersBlock;
