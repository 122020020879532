import React, { useContext } from "react";
import CustomButton from "../button";
import PortableText from "../portableText";
import { CarouselProvider, Slider, Slide, DotGroup, Dot } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import TexturedBackground from "../TexturedBackground";
import SEO from "../seo";
import SeoContext from "../context/seoContext";

const TestimonialsBlock = (node) => {

  const {title, logo, description, url, defaultImage, address} = useContext(SeoContext)

  return (
    <TexturedBackground>
      <SEO>
        <script type="application/ld+json">	
        {`{
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name":"${title}",
            "logo":"${logo.asset.url}",
            "image":"${defaultImage.asset.url}",
            "description":"${description}",
            "url":"${url}",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "${address.streetAndNumber}",
              "addressLocality": "${address.city}",
              "postalCode": "${address.zip}",
              "addressCountry": "${address.country}"
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "5",
              "reviewCount": "${node.testimonials?.length}"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": ${address.geolocation.lat},
              "longitude": ${address.geolocation.lng}
            },
            "review": [
              ${node.testimonials?.map(t => (`{
                "@type": "Review",
                "author": "${t.name}",
                "datePublished": "${t._createdAt.split("T")[0]}",
                "reviewBody": "${t.quote}"
              }`))}
            ] 
          }`}
        </script>
      </SEO>
      <section className=" rounded-3xl md:rounded-container pb-56 pt-26  md:py-16 relative md:mx-16">
        <span
          className="absolute top-28 transform -translate-x-1/2 left-1/2 md:left-0 lg:top-8 md:-translate-y-1/2 lg:translate-y-0 font-extrabold text-blue"
          style={{ fontSize: 100 }}
        >
          “
        </span>
        <h3 className="flex justify-items-center text-base w-full">
          <strong className="uppercase pt-0 md:pt-0 text-center mx-auto">
            {node.title || "Our clients think"}
          </strong>
        </h3>
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={75}
          totalSlides={node.testimonials?.length}
          className="lg:p-4 pb-4 md:pb-36 xl:p-12 pt-18 py-3 px-4 md:px-8 w-full lg:w-7/12 max-w-2xl "
          infinite={true}
          isPlaying={true}
          playDirection="forward"
          interval={4000}
          role="listbox"
          aria-label="Carousel container"
        >
          <Slider aria-label="Testimonials slider" className=" xl:-mb-24" role="listbox">
            {node.testimonials?.map((n, i) => (
              <Slide role="option" index={n._key || i} key={n._key || i}>
                <PortableText blocks={n}></PortableText>
              </Slide>
            ))}
          </Slider>
          <DotGroup className="flex" role="listbox" aria-label="Dots container">
            {node.testimonials?.map((n, i) => (
              <Dot
                role="option"
                slide={n._key || i}
                key={n._key || i}
                className={`disabled:opacity-25 w-12 h-12 rounded-full transition-opacity flex items-center justify-center relative ${
                  i > 0 ? "ml-2" : "-ml-3 md:-ml-5"
                }`}
              >
                <span className="bg-white rounded-full w-3 h-3"></span>
              </Dot>
            ))}
          </DotGroup>
        </CarouselProvider>
      </section>
      <span className="absolute bottom-8 lg:bottom-16 w-full flex justify-center lg:justify-end lg:-right-36">
        {/* <CustomButton level="secondary" cb={() => console.log("Hello")}>
          {"See cases"}
        </CustomButton> */}
      </span>
    </TexturedBackground>
  );
};
export default TestimonialsBlock;
