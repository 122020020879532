import React, { useContext } from "react";
import CustomButton from "./button";
import TexturedBackground from "./TexturedBackground";
import CompatiblesContext from './../components/context/compatibleContext'

const Compatibles = (node) => {
  const compatibleContext = useContext(CompatiblesContext) || [];

  if (compatibleContext.length>0){
    return (
      <TexturedBackground>
        <div className="w-full p-6 lg:pl-12  relative z-1 -top-26  lg:top-0  rounded-3xl md:rounded-container ">
          <h4 className=" pt-24 lg:pt-0  ">{node.compatibles}</h4>
          <div className="pb-7 pt-4 column" style={{columns: 2, columnWidth: 300}}>
            {compatibleContext.map((c, i) => (
              <li className="list-none" key={`filter-compatible-${i}`}>
                {c}
              </li>
            ))}
          </div>
        </div>
        <span className="absolute z-1  bottom-8 left-1/2 transform -translate-x-1/2 lg:bottom-24 lg:translate-x-1/2 lg:right-16 xl:right-0">
          {/* <CustomButton level="secondary" cb={() => console.log("Hello")}>
            {"See cases"}
          </CustomButton> */}
        </span>
      </TexturedBackground>
    );
  }
  return (null);
};

export default Compatibles;
