import React from 'react'
import {Link} from 'gatsby'

const InternalLink = ({children, link, type}) => {
    switch(type) {
        case "page":
            return <Link to={`/${link}`}>{children}</Link>
        case "filter": 
            return <Link to={`/filter/${link}`}>{children}</Link>
        default: 
            return <Link to={`/${link}`}>{children}</Link>
    }
}

export default InternalLink