import React, { useState, useEffect, useRef, createContext, useCallback } from "react";
import ContainerFullWidth from "../fullWidthContainer";
import PortableText from "../portableText";
import SectionBackgroundImage from "./sectionBackgroundImage";
import TrackVisibility from "react-on-screen";
import slugify from "slugify";

export const ScrollContext = createContext();

const Section = ({ node }) => {
  const sectionRef = useRef(null);
  const [scale, setScale] = useState(0);

  const { internalName = "" } = node;
  const sluggedId = slugify(internalName, { lower: true });

  const processScrollPerc = useCallback(
    (ref) => {
      if (!ref?.current) return;
      const element = ref.current;

      const rect = element?.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const { height, top } = rect;
      const scaleCount = (height + top) / (windowHeight * 2) - 0.4;
      setScale(1 - Math.min(Math.max(scaleCount, 0), 1));
    },
    [setScale]
  );

  useEffect(() => {
    if (!sectionRef.current) return;

    window.addEventListener("scroll", () => processScrollPerc(sectionRef));
    processScrollPerc(sectionRef);

    return function () {
      window.removeEventListener("scroll", () => processScrollPerc(sectionRef));
    };
  }, [sectionRef]);

  return (
    //purge: text-primary text-blue text-dark_blue text-navy text-dark_navy text-disabled_grey text-dove_blue text-white text-dusty_blue
    //purge: from-primary from-blue from-dark_blue from-navy from-dark_navy from-disabled_grey from-dove_blue from-white from-dusty_blue
    //purge: bg-primary bg-blue bg-dark_blue bg-navy bg-dark_navy bg-disabled_grey bg-dove_blue bg-dusty_blue
    //purge: justify-start justify-center justify-end

    <div
      className={`${!node.settings?.fullWidth ? "sm:mx-4 sm:max-w-full" : ""} `}
      ref={sectionRef}
      id={sluggedId}
    >
      <TrackVisibility partialVisibility once>
        {({ isVisible }) => (
          <ContainerFullWidth
            color={
              node.settings?.backgroundColor?.title?.split(" ").join("_").toLowerCase() || "white"
            }
            text={node.settings?.textColor?.title?.split(" ").join("_").toLowerCase() || "black"}
            containerClass={` flex flex-col xl:justify-center py-6 md:py-20 lg:py-40 relative`}
            sectionClass={`overflow-hidden ${node.settings?.radius
              ?.map((r) => `rounded-${r}-3xl md:rounded-${r}-container`)
              .join(" ")} ${
              node.settings?.isOverflow ? "-mb-6 md:-mb-12 relative z-10 shadow-2xl" : ""
            }`}
          >
            {node.settings?.backgroundImage && <SectionBackgroundImage node={node} scale={scale} />}
            <div
              className={`gsap-container ${
                isVisible ? "init" : ""
              } justify-center relative z-1 container py-16 ${
                node.settings?.isNarrow ? `max-w-5xl narrow-container` : ""
              }`}
            >
              <div
                className={`absolute inset-0 no-anim z-0 -my-6 md:-my-20 lg:-my-40 w-screen transform left-1/2 -translate-x-1/2 overflow-hidden `}
              >
                {node.settings?.gradient?.filter((g) => g === "t").length > 0 && (
                  <div
                    className={`absolute ${
                      node.settings?.radius?.find((r) => r === "t")
                        ? "rounded-t-3xl md:rounded-t-container"
                        : ""
                    } top-0 max-w-full h-1/2 bg-gradient-to-b sm:left-4 sm:right-4 from-${
                      node.settings?.backgroundColor?.title?.split(" ").join("_").toLowerCase() ||
                      "white"
                    }`}
                  ></div>
                )}
                {node.settings?.gradient?.filter((g) => g === "b").length > 0 && (
                  <div
                    className={`absolute ${
                      node.settings?.radius?.find((r) => r === "b")
                        ? "rounded-b-3xl md:rounded-b-container"
                        : ""
                    } bottom-0 max-w-full h-1/2 bg-gradient-to-t sm:left-4 sm:right-4 from-${
                      node.settings?.backgroundColor?.title?.split(" ").join("_").toLowerCase() ||
                      "white"
                    }`}
                  ></div>
                )}
              </div>

              {node.content?.map((c, i) => (
                <ScrollContext.Provider value={scale} key={c._key}>
                  <PortableText blocks={c} scrollProgress={scale} />
                </ScrollContext.Provider>
              ))}
            </div>
          </ContainerFullWidth>
        )}
      </TrackVisibility>
    </div>
  );
};

export default Section;
