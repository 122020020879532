import React from "react";
import Image from "../Image";
import PortableText from "../portableText";

const SectionBackgroundImage = ({ node, scale }) => {
  //purge: opacity-5 opacity-10 opacity-15 opacity-20 opacity-25 opacity-30 opacity-40 opacity-50 opacity-60 opacity-70 opacity-75 opacity-80 opacity-90 opacity-95 opacity-100
  return (
    <div className={`absolute inset-0 w-full h-full`}>
      <Image
        className={`absolute inset-0 pointer-events-none z-0 flex items-center justify-center  transition-transform duration-700`}
        opacity={node.settings.backgroundOpacity}
        height={node.settings.backgroundSize === "custom" && node.settings.customSizes?.height}
        width={node.settings.backgroundSize === "custom" && node.settings.customSizes?.width}
        bgSize={node.settings.backgroundSize}
        style={{
          transform:
            node.settings.backgroundAnimation === "scroll__scale" &&
            `scale(${scale < 0.4 ? 0.4 : scale > 0.8 ? 0.8 : scale})`,
        }}
      >
        <PortableText blocks={node.settings.backgroundImage} />
      </Image>
    </div>
  );
};

export default SectionBackgroundImage;
