import React from "react";
import PortableText from "../portableText";

const FiltersBlock = (node) => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-3 2xl:grid-cols-4 gap-4 xl:gap-6 2xl:gap-8 w-full xl:w-4/5 mx-auto">
      {node.filters.map((nf) => (
        <PortableText blocks={nf} key={nf._id} />
      ))}
    </div>
  );
};
export default FiltersBlock;
