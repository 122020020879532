import React, { useEffect, useState } from "react";
import CustomLink from "../CustomLink";
import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImageData } from "gatsby-source-sanity";
import clientConfig from "../../../client-config";

const Filter = (node) => {
  const { title, slug, filter_image } = node;
  const [fluidProps, setFluidProps] = useState(
    getGatsbyImageData(filter_image.asset?.id, { maxWidth: 1800 }, clientConfig.sanity)
  );

  useEffect(() => {
    if (!filter_image) return;
    setFluidProps(
      getGatsbyImageData(filter_image.asset?.id, { maxWidth: 1800 }, clientConfig.sanity)
    );
  }, []);

  return (
    <CustomLink className="no-underline" to={`/filter/${slug.current}`}>
      <article className="relative cursor-pointer transform hover:-translate-y-2 py-2 md:py-0 bg-dark_navy md:pb-6 transition-transform duration-300 group overflow-hidden border-dark_blue border-2 rounded-2xl md:rounded-3xl xl:rounded-4xl h-48 sm:h-60 md:h-80 flex flex-col items-center justify-center">
        <div className="group-hover:filter-blur transition-all w-full h-full flex items-center justify-center absolute z-hidden">
          {!filter_image && <img src="../bottle_big.jpg" className="w-full h-full" />}
          {filter_image && (
            <GatsbyImage
              image={fluidProps}
              className="opacity-50 w-4/5 mx-auto h-36 sm:h-48 md:h-64"
              objectFit="contain"
              alt={title}
            />
          )}
        </div>

        <h5 className="text-center mt-auto text-sm leading-tight px-4 lg:px-6 xl:px-12 lg:text-lg uppercase font-bold w-full">
          {title}
        </h5>
      </article>
    </CustomLink>
  );
};

export default Filter;
