import React from "react";

const VideoBlock = ({ node }) => {

  // if (node.space === "s") return <div className="w-full h-2 md:h-4"></div>;
  // if (node.space === "l") return <div className="w-full h-10 md:h-20"></div>;

  function onClickPlay(){
    var videoBlock = document.getElementsByClassName("video-block-"+node._key);
    if (videoBlock){
      videoBlock = videoBlock[0];
      var video = videoBlock.getElementsByTagName("video");
      if (video){
        video = video[0];
        video.play();
      }
    }
  }
  function onPlayVideo(){

    var videoBlock = document.getElementsByClassName("video-block-"+node._key);
    if (videoBlock){
      videoBlock = videoBlock[0];
      videoBlock.classList.add("playing");
    }
    
  }
  function onPauseVideo(){

    var videoBlock = document.getElementsByClassName("video-block-"+node._key);
    if (videoBlock){
      videoBlock = videoBlock[0];
      videoBlock.classList.remove("playing");
    }
    
  }

  
  
  return (
    <div className={"video-block video-block-"+node._key}>
      {/* {JSON.stringify(node.asset)} */}
      {/* {JSON.stringify(node.thumbnail)} */}
      {/* <img
                
                src={node.thumbnail.asset.url}
                alt="SIGUNA - OEM oil filters for the marine & industry"
                width={Math.floor(node.thumbnail.asset.metadata.dimensions.width / 1)}
                height={Math.floor(node.thumbnail.asset.metadata.dimensions.height / 1)}
              /> */}
      <div className="video-wrap" style={{lineHeight: 0}}>
        <video onPlay={onPlayVideo} onPause={onPauseVideo} className={"overflow-hidden rounded-container"} style={{borderRadius : 29}}controls playsinline poster={node.thumbnail.asset.url ? node.thumbnail.asset.url : null}>
          <source src={node.asset.url} ></source> 
        </video>
        <span onClick={onClickPlay} className={"play-button"}></span>
      </div>
     
    </div>
  );
};


export default VideoBlock;
